import Axios from 'axios'
import PrismApiError from './PrismApiError'
import Logger from '../utils/Logger'
import { IPrismApp } from './Models'

export const BASE_URL = `${process.env.REACT_APP_API_URL}`
Logger.log(`Connecting to ${BASE_URL}`)

export default class ApiManager {
    constructor(private apiKey: string) {}

    getAllApps(): Promise<{ apps: IPrismApp[] }> {
        return this.getImpl('/api/v1/apps')
    }

    deleteApp(appId: string): Promise<void> {
        return this.postImpl('/api/v1/apps/delete', { appId })
    }

    createNewApp(app: IPrismApp): Promise<void> {
        return this.postImpl('/api/v1/apps/create', { app })
    }

    editApp(app: IPrismApp): Promise<void> {
        return this.postImpl('/api/v1/apps/update', { app })
    }

    createHeaders() {
        return {
            'x-prism-auth-admin': this.apiKey
        }
    }

    private getImpl(path: string) {
        const self = this
        return Promise.resolve() //
            .then(function() {
                return Axios.get(`${BASE_URL}${path}`, {
                    headers: self.createHeaders()
                })
            })
            .then(function(response) {
                return response.data
            })
            .then(function(responseData) {
                if (responseData.status === 100) {
                    return responseData.data
                }

                if (responseData.status) {
                    throw new PrismApiError(`Error ${responseData.status} - ${responseData.description}`)
                }

                Logger.error(responseData)

                if (responseData.status) {
                    throw new PrismApiError(`Unexpected data from the api`)
                }
            })
    }

    private postImpl(path: string, data: any) {
        const self = this
        return Promise.resolve() //
            .then(function() {
                return Axios.post(`${BASE_URL}${path}`, data, {
                    headers: self.createHeaders()
                })
            })
            .then(function(response) {
                return response.data
            })
            .then(function(responseData) {
                if (responseData.status === 100) {
                    return responseData.data
                }

                if (responseData.status) {
                    throw new PrismApiError(`Error ${responseData.status} - ${responseData.description}`)
                }

                Logger.error(responseData)

                if (responseData.status) {
                    throw new PrismApiError(`Unexpected data from the api`)
                }
            })
    }
}
