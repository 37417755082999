import { Component } from 'react'
import { Row, Col, Card, Tooltip, Button } from 'antd'
import React from 'react'
import { IPrismApp } from '../api/Models'
export default class AppCellContainer extends Component<
    {
        onAppEditClicked: () => void
        onAppDeleteClicked: () => void
        app: IPrismApp
    },
    {}
> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    createList(allRows: string[]) {
        return allRows.map((row, idx) => {
            return (
                <li key={row + idx}>
                    <code>{row}</code>
                </li>
            )
        })
    }

    render() {
        const app = this.props.app
        const self = this

        const metadata = JSON.stringify(app.meta, null, 2)

        return (
            <div>
                <Row  align="middle" justify="center">
                    <Col xs={{ span: 24 }} style={{ marginTop: 30 }}>
                        <Card title={app.appName} extra={`ID: ${app.appId}`}>
                            <div>
                                <Row justify="center">
                                    <Col xs={{ span: 24 }}>
                                        <p>
                                            <b>Container ID:</b> {app.appContainerId}
                                        </p>
                                        <b>API Keys:</b>
                                        <ul>{self.createList(app.apiAccess)}</ul>
                                        <b>Routing Map:</b>
                                        <ul>{self.createList(app.routeTable)}</ul>
                                        <b>Metadata:</b>
                                        <pre>
                                            {metadata // Just removing the "{" and "}" - first and last lines
                                                .split('\n')
                                                .slice(1, metadata.split('\n').length - 1)
                                                .join('\n')}
                                        </pre>
                                    </Col>
                                </Row>

                                <Row  justify="end">
                                    <Tooltip title="Permanently delete the application from the API. This does not remove the app container. You need to do this manually.">
                                        <Button
                                            danger
                                            disabled={false}
                                            onClick={() => {
                                                self.props.onAppDeleteClicked()
                                            }}
                                        >
                                            Delete App
                                        </Button>
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                    <Button
                                        disabled={false}
                                        onClick={() => {
                                            self.props.onAppEditClicked()
                                        }}
                                    >
                                        Edit App
                                    </Button>
                                </Row>
                                {/* <pre>{JSON.stringify(node, undefined, 2)}</pre> */}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
