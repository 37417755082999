import { Component } from 'react'
import { Row, Modal, Input, Col } from 'antd'
import React from 'react'
import { IPrismApp } from '../api/Models'
import Utils from '../utils/Utils'
export default class ModalEditApp extends Component<
    {
        editApp: (app: IPrismApp | undefined) => void
        appOriginal: IPrismApp
    },
    {
        app: IPrismApp
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            app: props.appOriginal
        }
    }

    render() {
        const self = this
        const app = self.state.app

        return (
            <div>
                <Modal
                    title="Edit App"
                    okText="Edit"
                    onCancel={() => self.props.editApp(undefined)}
                    onOk={() => {
                        self.props.editApp(app)
                    }}
                    visible={true}
                >
                    <div>
                        <Row>
                            <Col xs={{ span: 12 }}>
                                App Name
                                <Input
                                    value={self.state.app.appName || ''}
                                    style={{ maxWidth: 200 }}
                                    onChange={(e) => {
                                        const newApp = Utils.copyObject(app)
                                        newApp.appName = `${e.target.value}`
                                        self.setState({ app: newApp })
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 12 }}>
                                Container ID
                                <Input
                                    value={self.state.app.appContainerId || ''}
                                    style={{ maxWidth: 200 }}
                                    onChange={(e) => {
                                        const newApp = Utils.copyObject(app)
                                        newApp.appContainerId = `${e.target.value}`.trim()
                                        self.setState({ app: newApp })
                                    }}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: 20 }} />
                        <Row>
                            <ul>
                                <li>
                                    API Access Keys and Levels <code>[key:level]</code>
                                </li>
                                <li>
                                    Example <code>secretkey:8</code>
                                </li>
                            </ul>
                            <br />
                            <br />
                            <i>Add a new row for a new access key.</i>
                            <Input.TextArea
                                value={(self.state.app.apiAccess || ['']).join('\n')}
                                rows={3}
                                onChange={(e) => {
                                    const newApp = Utils.copyObject(app)
                                    newApp.apiAccess = `${e.target.value}`.split('\n')
                                    self.setState({ app: newApp })
                                }}
                            />
                        </Row>
                        <div style={{ height: 20 }} />
                        <Row>
                            <ul>
                                <li>
                                    Routing Map <code>[source:destination]</code>
                                </li>
                                <li>
                                    Example <code>/json:http://domain.com/secret/path</code>
                                </li>
                            </ul>
                            <br />
                            <br />
                            <i>Add a new row for a new set.</i>
                            <Input.TextArea
                                value={(self.state.app.routeTable || ['']).join('\n')}
                                rows={3}
                                onChange={(e) => {
                                    const newApp = Utils.copyObject(app)
                                    newApp.routeTable = `${e.target.value}`.split('\n')
                                    self.setState({ app: newApp })
                                }}
                            />
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}
