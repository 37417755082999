import { Component } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd'
import React from 'react'
import { IPrismApp } from '../api/Models'
import Toaster from '../ui/Toaster'
import ApiManager from '../api/ApiManager'
import deepEqual from 'deep-equal'
import csv from 'csv-parse'

export default class UploadContainer extends Component<
    {
        onUploadFinished: (appIdsToBeDeleted: string[]) => void
        apiKey: string
    },
    { fileList: any[]; uploading: boolean }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            fileList: [],
            uploading: false
        }
    }

    uploadJson(uploadedApps: IPrismApp[]) {
        // iterate through and make sure all apps are valid.
        // fetch the latest apps and run the de-duping logic
        // Upload / Delete
        const apiManager = new ApiManager(this.props.apiKey)
        const self = this
        Promise.resolve()
            .then(function() {
                uploadedApps.forEach(it => {
                    it.appId = it.appId.trim()
                    it.appName = it.appName.trim()
                    it.appContainerId = it.appContainerId.trim()
                    it.apiAccess = it.apiAccess.map(row => row.trim())
                    it.routeTable = it.routeTable.map(row => row.trim())
                })
            })
            .then(function() {
                return apiManager.getAllApps()
            })
            .then(function(apiResp) {
                const appToDelete: string[] = []

                const uploadedAppIds = uploadedApps.map(app => app.appId)
                const currentAppIds = apiResp.apps.map(app => app.appId)

                currentAppIds.forEach(appId => {
                    if (uploadedAppIds.indexOf(appId) < 0) {
                        appToDelete.push(appId)
                    }
                })

                const ps = [Promise.resolve()]

                uploadedApps.forEach(app => {
                    ps.push(self.uploadAppIfNeeded(app, apiResp.apps))
                })

                return Promise.all(ps).then(function() {
                    self.props.onUploadFinished(appToDelete)
                })
            })
            .catch(err => Toaster.toast(err))
    }

    uploadAppIfNeeded(newApp: IPrismApp, currentApps: IPrismApp[]) {
        const self = this
        return Promise.resolve() //
            .then(function() {
                const existingApp = currentApps.find(it => it.appId === newApp.appId)
                if (existingApp && deepEqual(existingApp, newApp)) {
                    console.log(`No new changes in ${existingApp.appId}`)
                    return Promise.resolve()
                }

                if (!existingApp) {
                    return new ApiManager(self.props.apiKey).createNewApp(newApp)
                }

                return new ApiManager(self.props.apiKey).editApp(newApp)
            })
    }

    render() {
        const self = this
        return (
            <div>
                <Upload
                    beforeUpload={file => {
                        this.setState(state => ({
                            fileList: [...state.fileList, file]
                        }))
                        const reader = new FileReader()
                        reader.readAsText(file, 'UTF-8')
                        reader.onload = function(evt) {
                            const rawFileContent = `${evt.target?.result}`.trim()
                            if (rawFileContent.startsWith('{')) {
                                try {
                                    const jsonData = JSON.parse(rawFileContent)
                                    if (!jsonData || !jsonData.apps) {
                                        Toaster.error('JSON file should have a key of "apps"')
                                    }
                                    self.uploadJson(jsonData.apps)
                                } catch (error) {
                                    Toaster.error('Invalid JSON')
                                }
                            }

                            // assume csv and start to parse
                            csv(
                                rawFileContent,
                                {
                                    columns: true, //
                                    skip_empty_lines: true, //
                                    from_line: 2
                                }, //
                                function(err, output: any[]) {
                                    if (err) {
                                        Toaster.toast(err)
                                    } else {
                                        if (output.length > 0) {
                                            const keys = Object.keys(output[0]).sort()
                                            const expectedKeys = 'appId	appContainerId	appName	apiAccess	meta_version	meta_author	meta_doi_title	meta_doi_link	meta_help_link	meta_type	meta_primary_con	meta_year	meta_outcome	meta_visible'
                                                .split(/\s/)
                                                .map(it => it.trim())
                                                .sort()

                                            if (keys.length !== expectedKeys.length) {
                                                Toaster.error('Keys in CSV do not match the expected number of keys')
                                                return
                                            }

                                            for (let index = 0; index < keys.length; index++) {
                                                if (keys[index] !== expectedKeys[index]) {
                                                    Toaster.error('Unexpected keys in the CSV file')
                                                    return
                                                }
                                            }
                                        }

                                        const apps: IPrismApp[] = []
                                        output.forEach(row => {
                                            const modelId = (row.appId || '').trim()
                                            apps.push({
                                                appId: modelId,
                                                appName: (row.appName || '').trim(),
                                                appContainerId: (row.appContainerId || '').trim() || modelId,
                                                apiAccess: `${row.apiAccess || ''}`.split(',').map(it => it.trim()),
                                                routeTable: [
                                                    `/run$$http://srv-captain--model-${modelId}/ocpu/library/${modelId}Prism/R/gateway/json$$1,2`,
                                                    `/async/run$$http://srv-captain--model-${modelId}/ocpu/library/${modelId}Prism/R/gatewayasync/json$$2`,
                                                    `/tmp/$$http://srv-captain--model-${modelId}/ocpu/tmp/$$1,2`
                                                ],
                                                meta: {
                                                    version: (row.meta_version || '').trim(),
                                                    author: (row.meta_author || '').trim(),
                                                    doiTitle: (row.meta_doi_title || '').trim(),
                                                    doiLink: (row.meta_doi_link || '').trim(),
                                                    helpLink: (row.meta_help_link || '').trim(),
                                                    type: (row.meta_type || '').trim(),
                                                    primaryCondition: (row.meta_primary_con || '').trim(),
                                                    year: (row.meta_year || '').trim(),
                                                    outcome: (row.meta_outcome || '').trim(),
                                                    visible: (row.meta_visible || '').trim()
                                                }
                                            })
                                        })

                                        self.uploadJson(apps)
                                    }
                                }
                            )
                        }
                        reader.onerror = function(evt) {
                            Toaster.error('Error during reading the file')
                        }

                        return false
                    }}
                    fileList={[]}
                    onRemove={file => {
                        this.setState(state => {
                            const index = state.fileList.indexOf(file)
                            const newFileList = state.fileList.slice()
                            newFileList.splice(index, 1)
                            return {
                                fileList: newFileList
                            }
                        })
                    }}
                >
                    <Button type="dashed" shape="round" icon={<UploadOutlined />} size="large">
                        Import (csv/json)
                    </Button>
                </Upload>
            </div>
        );
    }
}
