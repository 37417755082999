import { Component } from 'react'
import { LoginOutlined } from '@ant-design/icons';
import { Row, Button, Col, Input } from 'antd'
import React from 'react'
import ApiManager from '../api/ApiManager'
import Toaster from '../ui/Toaster'

export default class LoginContainer extends Component<
    {
        onLoginSuccess: (apiKey: string) => void
    },
    {
        apiKey: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            apiKey: ''
        }
    }

    tryToLogin() {
        const self = this
        const apiKey = self.state.apiKey
        new ApiManager(apiKey)
            .getAllApps()
            .then(function(data) {
                // ignore data
                self.props.onLoginSuccess(apiKey)
                Toaster.success('Authorization successful!')
            })
            .catch(err => {
                Toaster.toast(err)
            })
    }

    render() {
        const self = this
        return (
            <div>
                <Row  align="middle" justify="center">
                    <Col xs={{ span: 12 }} lg={{ span: 4 }} style={{ textAlign: 'center' }}>
                        Enter your API key
                        <div style={{ height: 10 }} />
                        <Row>
                            <Input
                                value={self.state.apiKey || ''}
                                onChange={e => {
                                    self.setState({ apiKey: `${e.target.value}` })
                                }}
                            />
                        </Row>
                    </Col>
                </Row>
                <div style={{ height: 30 }} />
                <Row  align="middle" justify="center">
                    <Button
                        onClick={() => {
                            self.tryToLogin()
                        }}
                        type="primary"
                        shape="round"
                        icon={<LoginOutlined />}
                        size="large"
                    >
                        Login
                    </Button>
                </Row>
            </div>
        );
    }
}
