import { Component } from 'react'
import { Row } from 'antd'
import React from 'react'
import ApiManager from '../api/ApiManager'
import Toaster from '../ui/Toaster'
import { IPrismApp } from '../api/Models'
import AppCellContainer from './AppCellContainer'
export default class AppsContainer extends Component<
    {
        onAppEditClicked: (app: IPrismApp) => void
        onAppDeleteClicked: (app: IPrismApp) => void
        apiKey: string
    },
    {
        apps: IPrismApp[] | undefined
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            apps: undefined
        }
    }

    componentDidMount() {
        const self = this
        new ApiManager(this.props.apiKey)
            .getAllApps()
            .then(function(data) {
                self.setState({ apps: data.apps })
            })
            .catch(err => {
                Toaster.toast(err)
            })
    }

    createAllApps(apps: IPrismApp[]) {
        const self = this
        return apps.map(app => {
            return (
                <div key={app.appId}>
                    <AppCellContainer
                        app={app}
                        onAppDeleteClicked={() => self.props.onAppDeleteClicked(app)}
                        onAppEditClicked={() => self.props.onAppEditClicked(app)}
                    />
                </div>
            )
        })
    }

    render() {
        const self = this

        const apps = self.state.apps

        if (!apps) {
            return (
                <div>
                    <Row  align="middle" justify="center">
                        Loading...
                    </Row>
                </div>
            )
        }

        return (
            <div>
                {self.createAllApps(apps)}
            </div>
        )
    }
}
