import React, { RefObject, Component } from 'react'
import { RouteComponentProps } from 'react-router'
import * as GlobalActions from '../redux/actions/GlobalActions'
import { connect } from 'react-redux'
import LoginContainer from './LoginContainer'
import AppsContainer from './AppsContainer'
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Row, Modal, Col } from 'antd'
import Toaster from '../ui/Toaster'
import ApiManager, { BASE_URL } from '../api/ApiManager'
import ModalCreateNewApp from './ModalCreateNewApp'
import { IPrismApp } from '../api/Models'
import ModalEditApp from './ModalEditApp'
import Utils from '../utils/Utils'
import UploadContainer from './UploadContainer'

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

class PageRoot extends Component<
    RootPageInterface,
    {
        collapsed: boolean
        apiKey: string
        isLoading: boolean
        modalShowing: 'EDITING_MODAL' | 'NEW_APP_MODAL' | undefined
        appToEdit: IPrismApp | undefined
    }
> {
    private mainContainer: RefObject<HTMLDivElement>
    private endOfPage: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props)
        this.mainContainer = React.createRef()
        this.endOfPage = React.createRef()
        this.state = {
            modalShowing: undefined,
            appToEdit: undefined,
            collapsed: false,
            isLoading: false,
            apiKey: process.env.REACT_APP_PRISM_AUTH_HEADER || ''
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount()
        }
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.isMobile) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    createNewAppModal() {
        const self = this
        if (this.state.modalShowing === 'NEW_APP_MODAL') {
            return (
                <div>
                    <ModalCreateNewApp
                        createNewApp={app => {
                            if (!app) {
                                self.setState({ modalShowing: undefined })
                                return
                            }

                            self.setState({ modalShowing: undefined, isLoading: true })
                            new ApiManager(self.state.apiKey)
                                .createNewApp(app)
                                .then(function() {
                                    Toaster.success('New App Created!')
                                })
                                .catch(err => Toaster.toast(err))
                                .then(function() {
                                    self.setState({ isLoading: false })
                                })
                        }}
                    />
                </div>
            )
        }

        return <div />
    }

    createEditAppModal() {
        const self = this
        const appToEdit = self.state.appToEdit
        if (this.state.modalShowing === 'EDITING_MODAL' && !!appToEdit) {
            return (
                <div>
                    <ModalEditApp
                        appOriginal={appToEdit}
                        editApp={app => {
                            if (!app) {
                                self.setState({ modalShowing: undefined })
                                return
                            }

                            self.setState({ modalShowing: undefined, isLoading: true })
                            new ApiManager(self.state.apiKey)
                                .editApp(app)
                                .then(function() {
                                    Toaster.success('App updated!')
                                })
                                .catch(err => Toaster.toast(err))
                                .then(function() {
                                    self.setState({ isLoading: false })
                                })
                        }}
                    />
                </div>
            )
        }

        return <div />
    }

    render() {
        const self = this

        if (!self.state.apiKey) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <LoginContainer onLoginSuccess={apiKey => self.setState({ apiKey })} />
                </div>
            )
        }

        if (self.state.isLoading) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row  align="middle" justify="center">
                        Loading...
                    </Row>
                </div>
            )
        }

        return (
            <div>
                <div style={{ height: 60 }} />
                <Row  align="middle" justify="center">
                    <Col xs={{ span: 23 }} lg={{ span: 16 }} style={{ marginTop: 30 }}>
                        <Row  align="middle" justify="center">
                            <Button
                                onClick={() => {
                                    self.setState({ modalShowing: 'NEW_APP_MODAL' })
                                }}
                                type="primary"
                                shape="round"
                                icon={<PlusCircleOutlined />}
                                size="large"
                            >
                                Create New App
                            </Button>
                        </Row>
                        <div style={{ height: 30 }} />
                        <Row  align="middle" justify="end">
                            <UploadContainer
                                apiKey={self.state.apiKey}
                                onUploadFinished={appsToBeDeleted => {
                                    Toaster.success('Upload finished')

                                    if (appsToBeDeleted.length) {
                                        Modal.confirm({
                                            title: `Delete apps?`,
                                            content: 'Do you want to delete these apps? \n' + appsToBeDeleted.join(' , '),
                                            onOk() {
                                                self.setState({ isLoading: true })
                                                const apiManager = new ApiManager(self.state.apiKey)
                                                const promises = Promise.resolve()
                                                appsToBeDeleted.forEach(app => {
                                                    promises.then(() => {
                                                        return apiManager.deleteApp(app)
                                                    })
                                                })
                                                promises
                                                    .then(function() {
                                                        Toaster.success('Deleted')
                                                    })
                                                    .catch(err => Toaster.toast(err))
                                                    .then(function() {
                                                        return Utils.getDelayedPromise(1000)
                                                    })
                                                    .then(function() {
                                                        self.setState({ isLoading: false })
                                                    })
                                            },
                                            onCancel() {
                                                Promise.resolve()
                                                    .then(function() {
                                                        self.setState({ isLoading: true })
                                                        return Utils.getDelayedPromise(1000)
                                                    })
                                                    .then(function() {
                                                        self.setState({ isLoading: false })
                                                    })
                                            }
                                        })
                                    } else {
                                        Promise.resolve()
                                            .then(function() {
                                                self.setState({ isLoading: true })
                                                return Utils.getDelayedPromise(1000)
                                            })
                                            .then(function() {
                                                self.setState({ isLoading: false })
                                            })
                                    }
                                }}
                            />
                            <Button
                                onClick={() => {
                                    // self.setState({ modalShowing: 'NEW_APP_MODAL' })
                                    new ApiManager(self.state.apiKey)
                                        .getAllApps()
                                        .then(function(data) {
                                            Toaster.success('Done!')
                                            console.log(JSON.stringify(data))
                                            const date = new Date()
                                                .toISOString()
                                                .split(':')
                                                .join('-')
                                                .split('.')[0]
                                            Utils.download(
                                                `prism-backup-${date}-${BASE_URL.split('://')[1]
                                                    .split(':')
                                                    .join('-')}.json`,
                                                JSON.stringify(data, null, 2)
                                            )
                                        })
                                        .catch(err => Toaster.toast(err))
                                }}
                                type="dashed"
                                shape="round"
                                icon={<DownloadOutlined />}
                                size="large"
                                style={{ marginLeft: 10 }}
                            >
                                Export
                            </Button>
                        </Row>
                        <div style={{ height: 30 }} />
                        {self.createNewAppModal()}
                        {self.createEditAppModal()}
                        <AppsContainer
                            apiKey={self.state.apiKey}
                            onAppDeleteClicked={app => {
                                Modal.confirm({
                                    title: `Delete ${app.appId}?`,
                                    content:
                                        'This will delete the app routing data, you will still have to delete the container. Are you sure about this? You cannot recover the app after you delete this.',
                                    onOk() {
                                        self.setState({ isLoading: true })
                                        new ApiManager(self.state.apiKey)
                                            .deleteApp(app.appId)
                                            .then(function() {
                                                Toaster.success('Deleted')
                                            })
                                            .catch(err => Toaster.toast(err))
                                            .then(function() {
                                                self.setState({ isLoading: false })
                                            })
                                    },
                                    onCancel() {
                                        // nothing
                                    }
                                })
                            }}
                            onAppEditClicked={app => {
                                self.setState({ modalShowing: 'EDITING_MODAL', appToEdit: app })
                            }}
                        />
                        <div style={{ height: 60 }} />
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        rootElementKey: state.globalReducer.rootElementKey,
        isMobile: state.globalReducer.isMobile
    }
}

export default connect(mapStateToProps, {
    emitSizeChanged: GlobalActions.emitSizeChanged
})(PageRoot)
