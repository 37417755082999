export default {
    copyObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj)) as T
    },

    isMobile() {
        return window.innerWidth < 768
    },

    getDelayedPromise(timeMs: number) {
        if (!timeMs) {
            return Promise.resolve()
        }

        return new Promise<void>((res, rej) => {
            setTimeout(() => {
                res()
            }, timeMs)
        })
    },

    download(filename: string, text: string) {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }
}
