import { Component } from 'react'
import { Row, Modal, Input } from 'antd'
import React from 'react'
import { IPrismApp } from '../api/Models'
export default class ModalCreateNewApp extends Component<
    {
        createNewApp: (app: IPrismApp | undefined) => void
    },
    {
        appId: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            appId: ''
        }
    }

    render() {
        const appId = this.state.appId.trim()
        const self = this

        return (
            <div>
                <Modal
                    title="Create New App"
                    okText="Create"
                    onCancel={() => self.props.createNewApp(undefined)}
                    onOk={() => {
                        if (!appId) {
                            self.props.createNewApp(undefined)
                            return
                        }

                        self.props.createNewApp({
                            appId,
                            appName: appId,
                            apiAccess: ['somekeyhere:someaccess'],
                            appContainerId: appId,
                            routeTable: ['/path:http://srv-captain--someapp/json'],
                            meta: {}
                        })
                    }}
                    visible={true}
                >
                    <div>
                        <p> Enter the new App ID here. Other parameters will be prefilled with example data and you can edit them later.</p>
                        <Row>
                            <Input
                                value={self.state.appId || ''}
                                onChange={e => {
                                    self.setState({ appId: `${e.target.value}` })
                                }}
                            />
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}
